/* eslint-disable no-lone-blocks */
/* eslint-disable prefer-template */
/* eslint-disable no-restricted-syntax */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Grid, Stack, Container, Box, FormControlLabel, Checkbox, MenuItem } from '@mui/material';
// import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFSelect } from '../../../components/hook-form';

import axiosInstance from '../../../utils/axios';
import { permissions } from '../Amenities';
// import { permission } from '../Amenities';

export default function AddPermission() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked, name } = event.target;
    
    // Handle the case for "all_listing" checkbox
    if (name === 'all_listing') {
      if (checked) {
        // Select all listing-related permissions (assuming they have a 'listing' in their name or ID)
        const allListingIds = permission
          .filter(option => option.name.toLowerCase().includes('listing')) // Filter based on "listing" keyword in permission name
          .map(option => String(option.id)); // Get the ids of all listing-related permissions
          
        setSelectedCheckboxes((prevSelected) => [
          ...prevSelected,
          ...allListingIds, // Add all listing permission IDs
        ]);
      } else {
        // If "all_listing" is unchecked, remove all listing-related permissions
        const allListingIds = permission
          .filter(option => option.name.toLowerCase().includes('listing'))
          .map(option => String(option.id));
    
        setSelectedCheckboxes((prevSelected) => 
          prevSelected.filter(id => !allListingIds.includes(id)) // Remove all listing permission IDs
        );
      }
    }
  
    // Handle the case for "all_user" checkbox
    else if (name === 'all_user') {
      if (checked) {
        // Select all user-related permissions (assuming they have a 'user' in their name or ID)
        const allUserIds = permission
          .filter(option => option.name.toLowerCase().includes('user')) // Filter based on "user" keyword in permission name
          .map(option => String(option.id)); // Get the ids of all user-related permissions
  
        setSelectedCheckboxes((prevSelected) => [
          ...prevSelected,
          ...allUserIds, 
        ]);
      } else {
    
        const allUserIds = permission
          .filter(option => option.name.toLowerCase().includes('user'))
          .map(option => String(option.id));
  
        setSelectedCheckboxes((prevSelected) => 
          prevSelected.filter(id => !allUserIds.includes(id)) 
        );
      }
    }
    
    else {
      // Regular checkbox change (not "all_listing" or "all_user")
      setSelectedCheckboxes((prevSelected) => {
        const newSelected = checked
          ? [...prevSelected, String(value)] // Add the current checkbox value if checked
          : prevSelected.filter((item) => item !== String(value)); // Remove it if unchecked
        return newSelected;
      });
    }
  };
  
  
  const [data, setdata] = useState([]);


  const [permission, setpermission] = useState([]);
  const GetAllAdmin = () => {
    axiosInstance
      .get('/subadmin')
      .then((response) => {
        if (response.data) {
          // console.log(response?.data);
          const data = response?.data?.data;
          setdata(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    permissions()
      .then((e) => {
        setpermission(e?.data?.data);
      })
      .catch((e) => {
        console.log(e);
      });
    GetAllAdmin();
  }, []);

  const NewProviderSchema = Yup.object().shape({
    user_id: Yup.string().required('user is required'),
  });

  const defaultValues = useMemo(
    () => ({
      user_id: state?.id || '',
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewProviderSchema),
    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;
  const userid = watch('user_id');
  // console.log(userid)
  useEffect(() => {
    if (state !== null) {
      const result = state?.permissions?.map((item) => String(item.id));
      setSelectedCheckboxes(result || []);
    } else {
      const filterSubadmin = data?.filter((e) => e?.id === userid);
      const result = filterSubadmin?.[0]?.permissions?.map((e) => String(e.id));
      // console.log(data?.permission)
      setSelectedCheckboxes(result || []);
    }
    // console.log(result);
  }, [userid, data]);
  const onSubmit = async (data) => {
    console.log(data);
    try {
      const Data = new FormData();
      {
        selectedCheckboxes?.forEach((Ids, index) => Data.append(`permissions[${index}]`, Ids));
      }

      Data.append('user_id', data?.user_id);
      await axiosInstance.post('/permission', Data).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message, { variant: 'success' });

          navigate('/dashboard/subadmin');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Permission" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} mt={5}>
            <Card sx={{ p: 3 }}>
              <Box>
                <Grid item xs={12}>
                  <RHFSelect
                    name={'user_id'}
                    label="Select Subadmin"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.length === 0 && <MenuItem value="">No Subadmin found</MenuItem>}
                    <MenuItem value="subadmin" disabled selected>
                      Select Subadmin{' '}
                    </MenuItem>
                    {
                      // eslint-disable-next-line arrow-body-style
                      data?.map((e) => {
                        return (
                          <MenuItem key={e.id} value={e.id}>
                            {e.first_name + ' ' + e?.last_name}
                          </MenuItem>
                        );
                      })
                    }
                  </RHFSelect>

                  {/* </RHFSelect> */}
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Permission</h2>
                <Grid item xs={12}>
                  {permission?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option?.id}
                      control={
                        <Checkbox
                          checked={selectedCheckboxes.includes(String(option?.id))}
                          onChange={handleCheckboxChange}
                          name={option?.name}
                          value={option?.id}
                        />
                      }
                      label={option.name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                    />
                  ))}
                </Grid>
              </Box>

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Add Permission
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
