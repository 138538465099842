/* eslint-disable no-dupe-else-if */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import EmailIcon from '@mui/icons-material/Email';
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
import ImageIcon from '@mui/icons-material/Image';
import VerifiedIcon from '@mui/icons-material/Verified';
import ReportIcon from '@mui/icons-material/Report';
import ReviewsIcon from '@mui/icons-material/Reviews';
import { PATH_DASHBOARD } from '../../../routes/paths';
// eslint-disable-next-line import/newline-after-import
import SvgIconStyle from '../../../components/SvgIconStyle';
const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  location: getIcon('ic_location'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const getNavConfig = (userRole) => {
  const storedDataString = localStorage.getItem('setitem');
  const data = JSON.parse(storedDataString);
  const subadmin = userRole?.role === 'subadmin';
  const getuser = userRole.permissions?.some((e) => e?.name === 'get_user');
  const getlisting = userRole.permissions?.some((e) => e?.name === 'get_listing');
  const adduser = userRole.permissions?.some((e) => e?.name === 'add_user');
  const alllisting = userRole.permissions?.some((e) => e?.name === 'all_listing');
  const addlisting = userRole.permissions?.some((e) => e?.name === 'add_listing');
  const alluser = userRole.permissions?.some((e) => e?.name === 'all_user');
  
  console.log(getlisting, 'getlisting');
  // const  data =[
  //   {
  //     name:'website1'
  //   },
  //   {
  //     name:'website2'
  //   },
  //   {
  //     name:'website3'
  //   },
  // ]
  // if (userRole === 'admin') {
  //   return [
  //     {
  //       subheader: 'Admin',
  //       items: [
  //         { title: 'Websites', path: PATH_DASHBOARD.general.website, icon: ICONS.ecommerce },
  //         { title: 'Agents', path: PATH_DASHBOARD.general.subadmin, icon: ICONS.user },
  //       ],
  //     },
  //     {
  //       subheader: 'Websites',
  //       items: [
  //         {
  //           title: 'Main Websites',
  //           path: PATH_DASHBOARD.weedowl.root,
  //           icon: ICONS.ecommerce,
  //           children: [
  //             { title: 'Product', path: PATH_DASHBOARD.weedowl.product },
  //             { title: 'Category', path: PATH_DASHBOARD.weedowl.category },
  //           ],
  //         },
  //       ],
  //     },
  //   ];
  // } else {
  if (userRole?.role === 'admin') {
    return [
      {
        subheader: 'Admin',
        items: [
          { title: 'Dashboard', path: '/dashboard/analytics', icon: ICONS.dashboard },
          { title: 'User', path: '/dashboard/users', icon: ICONS.user },
          // { title: 'Host', path: '/dashboard/host', icon: ICONS.user },
          { title: 'Listing', path: '/dashboard/services', icon: ICONS.booking },
          { title: 'Subadmin', path: PATH_DASHBOARD.general.subadmin, icon: ICONS.user },
          { title: 'Permission', path: PATH_DASHBOARD.subadminmanger.addpermission, icon: <VerifiedIcon /> },
          { title: 'SuperSubAdmin', path: PATH_DASHBOARD.subadminmanger.subadminmanger, icon: ICONS.user },
          // { title: 'Location', path: PATH_DASHBOARD.location.loaction, icon: <AddLocationAltIcon/> },
          { title: 'Features', path: PATH_DASHBOARD.feature.feature, icon: <AddLocationAltIcon /> },
          { title: 'Listing Type', path: PATH_DASHBOARD.type.type, icon: <AddLocationAltIcon /> },
          { title: 'Contact', path: '/dashboard/contact', icon: <EmailIcon /> },
          { title: 'Advertisement', path: '/dashboard/advertisement', icon: <FeaturedVideoIcon /> },
          { title: 'Title', path: '/dashboard/title', icon: <FeaturedVideoIcon /> },
          { title: 'logs', path: '/dashboard/logs', icon: <SyncAltIcon /> },
          { title: 'Banner Image', path: '/dashboard/banner-image', icon: <ImageIcon /> },
          { title: 'Listing Report', path: '/dashboard/report-lisitng', icon: <ReportIcon /> },
          { title: 'Listing reviews', path: '/dashboard/review-lisitng', icon: <ReviewsIcon /> },
          { title: 'Call Logs', path: PATH_DASHBOARD.logs.calllogs, icon: <SyncAltIcon /> },
        ],
      },
      {
        subheader: 'Company',
        items: [
          {
            title: 'Company',
            path: PATH_DASHBOARD.company.aboutus,
            icon: ICONS.ecommerce,
            children: [
              { title: 'About Us', path: PATH_DASHBOARD.company.aboutus },
              { title: 'Careers', path: PATH_DASHBOARD.company.Our_contact },
              { title: 'Blog', path: PATH_DASHBOARD.company.Blog },
              { title: 'Rental Guides', path: PATH_DASHBOARD.company.Rental },
              // { title: 'Our Contact', path:  PATH_DASHBOARD.company.Our_contact},
            ],
          },
        ],
      },
      {
        subheader: 'Support',
        items: [
          {
            title: 'Support',
            path: PATH_DASHBOARD.Support.report,
            icon: ICONS.ecommerce,
            children: [
              { title: 'Report An Issue', path: PATH_DASHBOARD.Support.report },
              { title: 'FAQs', path: PATH_DASHBOARD.Support.FAQs },
              { title: 'Terms of use', path: PATH_DASHBOARD.Support.Terms },
              { title: 'Privacy Policay', path: PATH_DASHBOARD.Support.Privacy },
              // { title: 'Advertise With Us', path:PATH_DASHBOARD.Support.Advertise },
            ],
          },
        ],
      },
    ];
  } else if (userRole?.role === 'subadmin_manager') {
    return [
      {
        subheader: 'Sub-Admin Manager',
        items: [
          // { title: 'User', path: '/dashboard/users', icon: ICONS.user },
          { title: 'Subadmin', path: PATH_DASHBOARD.general.subadmin, icon: ICONS.user },
          { title: 'Permission', path: PATH_DASHBOARD.subadminmanger.addpermission, icon: <VerifiedIcon /> },
          // { title: 'Host', path: '/dashboard/host', icon: ICONS.user },
          // { title: 'Listing', path: '/dashboard/services', icon: ICONS.booking },
        ],
      },
    ];
  } else if ((getuser || alluser||adduser) && subadmin && !getlisting && !alllisting&&addlisting) {
    return [
      {
        subheader: 'Sub-Admin',
        items: [{ title: 'User', path: '/dashboard/users', icon: ICONS.user }],
      },
    ];
  } else if ((alllisting || getlisting ||addlisting) && subadmin && !getuser && !alluser &&!adduser) {
    return [
      {
        subheader: 'Sub-Admin',
        items: [{ title: 'Listing', path: '/dashboard/services', icon: ICONS.booking }],
      },
    ];
  } else if ((getlisting || alllisting||addlisting) && (getuser || alluser ||adduser) && subadmin) {
    return [
      {
        subheader: 'Sub-Admin',
        items: [
          { title: 'User', path: '/dashboard/users', icon: ICONS.user },
          { title: 'Listing', path: '/dashboard/services', icon: ICONS.booking },
          // { title: 'Host', path: '/dashboard/host', icon: ICONS.user },
        ],
      },
    ];
  }
};

export default getNavConfig;
